import axios from "axios";
import {DOSSIER_FETCH_STATUS} from "@/constants/DossierStatus";
import {DossierStatutStore} from "@/store/dossier/statut";
import {CommentaireStore} from "@/store/dossier/commentaires";

export const DossierStore = {
    namespaced: true,
    state: () => ({
        dossiers: [],
        selected_dossier: null,
        all_items_fetch_status: DOSSIER_FETCH_STATUS.initial,
        single_item_fetch_status: DOSSIER_FETCH_STATUS.initial,
    }),
    mutations: {
        setDossiers(state, dossiers) {
            state.dossiers = dossiers;
        },
        setDocuments(state, documents) {
          state.selected_dossier.documents = documents;
        },
        setFinancements(state, financements) {
          state.selected_dossier.financements = financements;
        },
        setHonoraires(state, honoraires) {
          state.selected_dossier.honoraires = honoraires;
        },
        setSelectedDossier(state, dossier) {
            state.selected_dossier = dossier;
        },
        updateSelectedDossier(state, dossier) {
            state.selected_dossier = {...state.selected_dossier, ...dossier};
        },
        setAllItemsFetchStatus(state, status) {
            state.all_items_fetch_status = status;
        },
        setSingleItemFetchStatus(state, status) {
            state.single_item_fetch_status = status;
        },
        addDossier(state, dossier) {
            state.dossiers = [dossier, ...state.dossiers];
        },
        removeDossier(state, dossier) {
            state.dossiers = state.dossiers.filter((d) => d.id !== dossier.id);
        },
    },
    getters: {
        getDossiers(state) {
            return state.dossiers;
        },
        getSelectedDossier(state) {
            return state.selected_dossier;
        },
        getSingleItemFetchStatus(state) {
            return state.single_item_fetch_status;
        },
        getAllItemsFetchStatus(state) {
            return state.all_items_fetch_status;
        },
    },
    actions: {
        async fetchDossiers({commit, dispatch, rootGetters}) {
            commit("setAllItemsFetchStatus", DOSSIER_FETCH_STATUS.loading);
            try {
                let response = await axios.get("/api/dossiers/", rootGetters["auth/getAuth"]);
                commit("setDossiers", response.data);
                commit("setAllItemsFetchStatus", DOSSIER_FETCH_STATUS.loaded);
            } catch (e) {
                commit("setAllItemsFetchStatus", DOSSIER_FETCH_STATUS.error);
                dispatch("annonce/annonceError", "Une erreur est survenue");
            }
        },
        async fetchDossierById({commit, dispatch, rootGetters}, id) {
            commit("setSingleItemFetchStatus", DOSSIER_FETCH_STATUS.loading);
            try {
                let response = await axios.get(`/api/dossiers/${id}`, rootGetters["auth/getAuth"]);
                commit("setSelectedDossier", response.data);
                commit("setSingleItemFetchStatus", DOSSIER_FETCH_STATUS.loaded);
            } catch (e) {
                commit("setSingleItemFetchStatus", DOSSIER_FETCH_STATUS.error);
                dispatch("annonce/annonceError", "Une erreur est survenue", {root: true});
            }
        },
        async getDossierStats({dispatch, rootGetters}, id) {
            try {
                let response = await axios.get(`/api/dossiers/${id}/stats/`, rootGetters["auth/getAuth"]);
                return response.data;
            } catch (e) {
                dispatch("annonce/annonceError", "Une erreur est survenue", {root: true});
                return null;
            }
        },
        async postDossier({commit, dispatch, rootGetters}, dossier) {
            try {
                let response = await axios.post("/api/dossiers/", dossier, rootGetters["auth/getAuth"]);
                commit("addDossier", response.data);
                commit("setSelectedDossier", response.data);
                return "success";
            } catch (e) {
                dispatch("annonce/annonceError", "Une erreur est survenue", {root: true});
                return "failure";
            }
        },
        async deleteDossier({commit, rootGetters}, dossier) {
            try {
                await axios.delete(`/api/dossiers/${dossier.id}`, rootGetters["auth/getAuth"]);
                commit("removeDossier", dossier);
            } catch (e) {
                throw Error("Impossible de supprimer le dossier");
            }
        },
        async getDossierPhotos({dispatch, rootGetters}, dossier) {
            try {
                let response = await axios.get(`/api/dossiers/${dossier.id}/download_photos/`, rootGetters["auth/getAuth"]);
                let contentType = response.headers['content-type'];
                console.log(`${contentType} vs application/x-zip-compressed`);
                if(contentType === "application/zip" || contentType === "application/x-zip-compressed" || contentType === "application/octet-stream") {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'photos.zip'); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                    dispatch("annonce/annonceSuccess", "Photos téléchargées.", {root: true})
                } else {
                    dispatch("annonce/annonceSuccess", response.data.message, {root: true});
                }
            } catch (e) {
                dispatch("annonce/annonceError", "Une erreur est survenue", {root: true});
            }
        },
        async createExport({dispatch, rootGetters}, payload) {
            try {
                let response = await axios.post(`/api/dossiers/${payload.id}/export/`,
                    {attachments: payload.files, documents: payload.documents, type: payload.type, factures: payload.factures}, {...rootGetters["auth/getAuth"], responseType: "blob"});
                let contentType = response.headers['content-type'];
                if(contentType === "application/zip" || contentType === "application/x-zip-compressed" || contentType === "application/octet-stream") {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'export.zip'); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                    dispatch("annonce/annonceSuccess", "Export téléchargé.", {root: true})
                } else if(contentType === "application/pdf") {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'export.pdf'); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                    dispatch("annonce/annonceSuccess", "Export téléchargé.", {root: true})
                } else {
                    dispatch("annonce/annonceSuccess", response.data.message, {root: true});
                }
            } catch (e) {
                dispatch("annonce/annonceError", "Une erreur est survenue", {root: true});
            }
        },

        async patchDossier({state, commit, dispatch, rootGetters}, dossier) {
            try {
                let response = await axios.patch(`/api/dossiers/${dossier.id}/`, dossier, rootGetters["auth/getAuth"]);
                commit("setSelectedDossier", response.data);
                if(state.dossiers.length > 0) {
                    dispatch("fetchDossiers");
                }
                return "success";

            } catch (e) {
                console.log(e);
                dispatch("annonce/annonceError", "Une erreur est survenue", {root: true});
            }
        },
        async updateDossierFinanceData({commit, dispatch, rootGetters}, id) {
            commit("setSingleItemFetchStatus", DOSSIER_FETCH_STATUS.loading);
            try {
                let response = await axios.get(`/api/dossiers/${id}/finance/`, rootGetters["auth/getAuth"]);
                commit("updateSelectedDossier", response.data);
                commit("setSingleItemFetchStatus", DOSSIER_FETCH_STATUS.loaded);
            } catch (e) {
                commit("setSingleItemFetchStatus", DOSSIER_FETCH_STATUS.error);
                dispatch("annonce/annonceError", "Une erreur est survenue", {root: true});
            }
        },
        async getAllDossierFinanceData({dispatch, rootGetters}) {
            try {
                let response = await axios.get(`/api/dossiers/finance_infos/`, rootGetters["auth/getAuth"]);
                return response.data;
            } catch (e) {
                dispatch("annonce/annonceError", "Une erreur est survenue", {root: true});
            }
        },
        async getDossierFinanceData({dispatch, rootGetters}, id) {
            try {
                let response = await axios.get(`/api/dossiers/${id}/finance_infos/`, rootGetters["auth/getAuth"]);
                return response.data;
            } catch (e) {
                dispatch("annonce/annonceError", "Une erreur est survenue", {root: true});
            }
        },
        async getStats({dispatch, rootGetters}, payload) {
            try {

                let response = await axios.get(`/api/dossiers/stats/?year=${payload.year}`, rootGetters["auth/getAuth"]);
                return response.data;
            } catch (e) {
                dispatch("annonce/annonceError", "Une erreur est survenue", {root: true});
            }
        }
    },
    modules: {
        statuts: DossierStatutStore,
        commentaires: CommentaireStore,
    }
}